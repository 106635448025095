import { useContext, useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { AppContext } from '../app/App';
import useJwt from '../hooks/useJwt';
import useAuth from '../hooks/useAuth';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

const NavBar = () => {
	const { theme, setTheme } = useContext(AppContext);
	const navigate = useNavigate();
	const [token, setToken] = useState(false);
	const [menuState, setMenuState] = useState('closed');
	const [menuDisplay, setMenuDisplay] = useState('none');
	const [closeMenuOverlayState, setCloseMenuOverlayState] = useState('nmo-invisible');
	const [backButton, setBackButton] = useState(false);
	const jwt = useJwt();
	const auth = useAuth();
	const location = useLocation();

	useEffect(
		() => {
			if (auth) {
				checkForTokenInLocalStorage();
			} else {
				localStorage.removeItem('token');
			}

			if (location.pathname === '/view-post') {
				setBackButton(true);
			}
		},
		// eslint-disable-next-line
		[],
	);

	const checkForTokenInLocalStorage = async () => {
		const token = await localStorage.getItem('token');

		if (token !== null || token !== undefined) {
			setToken(token);
		}
	};

	const toggleTheme = () => {
		let newTheme;

		if (theme === 'light') {
			newTheme = 'dark';
		} else {
			newTheme = 'light';
		}

		setTheme(newTheme);
		localStorage.setItem('theme', newTheme);
	};

	const toggleMenu = () => {
		if (menuState === 'closed') {
			setMenuState('open');
			setMenuDisplay('flex');
			setCloseMenuOverlayState('nmo-visible');
		} else {
			setMenuState('closed');
			setMenuDisplay('none');
		}
	};

	const signOut = () => {
		setMenuState('closed');
		setMenuDisplay('none');
		setToken(false);
		localStorage.removeItem('token');
		navigate('/home-atlast');
	};

	const closeMenu = () => {
		setMenuState('closed');
		setMenuDisplay('none');
		setCloseMenuOverlayState('nmo-invisible');
	};

	const goBack = () => {
		navigate('/home-atlast');
	};

	return (
		<>
			<nav className='navbar'>
				<div className='navbar-logo-container'>
					<Link to='/home-atlast'>
						{theme === 'dark' ? (
							<img src='/img/atlast_logo_light.png' alt='Nav-Icon' className='navbar-logo' />
						) : (
							<img src='/img/atlast_logo_dark.png' alt='Nav-Icon' className='navbar-logo' />
						)}
					</Link>
					<h3 className='navbar-title'>AtLast</h3>
				</div>
				<div className='navbar-actions'>
					{backButton && <input type='button' className='btn-md btn-secondary-solid' value='Back' onClick={goBack} />}
					<input type='button' className='btn-md btn-primary-solid' value='Toggle Theme' onClick={toggleTheme} />
					{!token && <input type='button' className='btn-md btn-primary-solid' value='Sign In' onClick={() => navigate('/sign-in')} />}
					{token && (
						<div className='navbar-user-container' onClick={toggleMenu}>
							<div className='navbar-user-image-container'>
								{jwt.name === 'Matt' ? (
									<img src='/img/pp_matt.png' className='navbar-user-image' alt='User-Profile' />
								) : (
									<img src='/img/pp_joy.png' className='navbar-user-image' alt='User-Profile' />
								)}
							</div>
							<span className='navbar-user'>{jwt.name}</span>
							{menuState === 'closed' ? (
								<i className='navbar-menu-icon'>
									<BsChevronDown />
								</i>
							) : (
								<i className='navbar-menu-icon'>
									<BsChevronUp />
								</i>
							)}
							<div className='navbar-menu' style={{ 'display': menuDisplay }}>
								<Link to='/home-atlast' className='navbar-menu-item' onClick={() => navigate('/home-atlast')}>
									Home AtLast
								</Link>
								<Link to='/admin-coming-next' className='navbar-menu-item' onClick={() => navigate('/admin-coming-next')}>
									Coming Next (Admin)
								</Link>
								<Link to='/new-post' className='navbar-menu-item' onClick={() => navigate('/new-post')}>
									New Post
								</Link>
								<Link to='/draft-posts' className='navbar-menu-item' onClick={() => navigate('/draft-posts')}>
									Draft Posts
								</Link>
								<Link to='/home-atlast' className='navbar-menu-item' onClick={signOut}>
									Sign Out
								</Link>
							</div>
						</div>
					)}
				</div>
			</nav>

			<div className={'navbar-menu-overlay ' + closeMenuOverlayState} onClick={closeMenu}></div>
		</>
	);
};

export default NavBar;
