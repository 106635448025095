import React from 'react';

const AddVideoOverlay = ({ acceptClick, cancelClick, overlayState, videoData, setVideoData }) => {
    const handleInputChange = (event) => {
        setVideoData({ ...videoData, [event.target.name]: event.target.value });
    };

    return (
        <section className={'add-video-overlay ' + overlayState}>
            <div className='add-video-overlay-container'>
                <h1 className='add-video-header'>Embed YouTube Video</h1>
                <div className='form-control mt-md'>
                    <label className='form-control-label'>Link</label>
                    <input type='text' name='link' value={videoData.link} className='form-input' onChange={handleInputChange} />
                </div>
                <div className='form-control mt-md'>
                    <label className='form-control-label'>Title</label>
                    <input type='text' name='title' value={videoData.title} className='form-input' onChange={handleInputChange} />
                </div>
                <div className='add-video-actions mt-md'>
                    <input type='button' className='btn-md btn-primary-solid' value='Embed' onClick={acceptClick} />
                    <input type='button' className='btn-md btn-secondary-solid' value='Cancel' onClick={cancelClick} />
                </div>
            </div>
        </section>
    );
};

export default AddVideoOverlay;
