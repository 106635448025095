import { useState, useRef } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import NavBar from '../../components/NavBar';
import AddVideoOverlay from '../../components/AddVideoOverlay';

const NewPostScreen = () => {
	const [overlayState, setOverlayState] = useState('overlay-animate-out');
	const [formData, setFormData] = useState({
		title: '',
		description: '',
		author: '',
		body: '',
		coverImage: '',
		isTitleValid: true,
		isDescriptionValid: true,
		isAuthorValid: true,
		isBodyValid: true,
		isCoverImageValid: true,
	});
	const [videoData, setVideoData] = useState({
		link: '',
		title: '',
		isLinkValid: true,
		isTitleValid: true,
	});
	const [cursorPosition, setCursorPosition] = useState(0);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [draftId, setDraftId] = useState();
	const [savedAt, setSavedAt] = useState('');
	const textareaRef = useRef();
	const hiddenImageFileInputRef = useRef();
	const hiddenCoverImageFileInputRef = useRef();
	const navigate = useNavigate();

	const handleInputChange = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleTextareaChange = (event) => {
		setFormData({ ...formData, body: event.target.value });
		setCursorPosition(textareaRef.current.selectionStart);
	};

	const addParagraph = () => {
		// splice
		const first = formData.body.substring(0, cursorPosition);
		const second = formData.body.substring(cursorPosition);

		// eslint-disable-next-line no-useless-concat
		const splicedString = first + '<p className="' + 'new-post-paragraph' + '"></p>' + second;

		// eslint-disable-next-line
		setFormData({ ...formData, body: splicedString });
		setCursorPosition(textareaRef.current.selectionStart);
	};

	const addEmphasis = () => {
		// splice
		const first = formData.body.substring(0, cursorPosition);
		const second = formData.body.substring(cursorPosition);

		// eslint-disable-next-line no-useless-concat
		const splicedString = first + '<em className="' + 'new-post-emphasis' + '"></em>' + second;

		setFormData({ ...formData, body: splicedString });
		setCursorPosition(textareaRef.current.selectionStart);
	};

	const addBold = () => {
		// splice
		const first = formData.body.substring(0, cursorPosition);
		const second = formData.body.substring(cursorPosition);

		// eslint-disable-next-line no-useless-concat
		const splicedString = first + '<strong className="' + 'new-post-bold' + '"></strong>' + second;

		setFormData({ ...formData, body: splicedString });
		setCursorPosition(textareaRef.current.selectionStart);
	};

	// Post images
	const addImage = () => {
		hiddenImageFileInputRef.current.click();
	};

	const uploadImage = (event) => {
		if (event.target.files.length > 0) {
			validateImage(event.target.files[0].size, event.target.files[0].type, event.target.files[0]);
		}
	};

	const validateImage = async (size, type, file) => {
		const maxSize = 10 * 1024 * 1024;
		let canAddImage = true;

		if (size > maxSize) {
			canAddImage = false;
		}

		const fileTypeArray = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];

		if (!fileTypeArray.includes(type)) {
			canAddImage = false;
		}

		if (canAddImage) {
			setIsButtonDisabled(true);

			// upload image and append html to post
			const loadingToast = toast.loading('Uploading image...');

			const bodyFormData = new FormData();

			bodyFormData.append('image', file);

			// const devUrl = 'http://localhost:3001/api/posts/upload-image';
			const productionUrl = '/api/posts/upload-image';

			const response = await axios({
				method: 'post',
				url: productionUrl,
				data: bodyFormData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			if (response.data.message === 'uploaded') {
				setIsButtonDisabled(false);
				toast.dismiss(loadingToast);
				toast.success('Image added successfully!');

				// append to the html
				// splice
				const first = formData.body.substring(0, cursorPosition);
				const second = formData.body.substring(cursorPosition);

				// eslint-disable-next-line no-useless-concat
				const splicedString =
					first +
					'<div className="' +
					'new-post-image-container' +
					'" />' +
					'<img src="' +
					'/uploads/' +
					file.name +
					'" className="' +
					'new-post-image' +
					'" /></div>' +
					second;

				setFormData({ ...formData, body: splicedString });
				setCursorPosition(textareaRef.current.selectionStart);
			} else {
				setIsButtonDisabled(false);
				toast.dismiss(loadingToast);
				toast.error('Image upload failed!');
			}
		} else {
			toast.error('Invalid image!');
		}
	};

	// Image caption
	const addCaption = () => {
		// splice
		const first = formData.body.substring(0, cursorPosition);
		const second = formData.body.substring(cursorPosition);

		// eslint-disable-next-line no-useless-concat
		const splicedString =
			first + '<div className="' + 'new-post-image-caption-container' + '"><p className="' + 'new-post-image-caption' + '"></p></div>' + second;

		// eslint-disable-next-line
		setFormData({ ...formData, body: splicedString });
		setCursorPosition(textareaRef.current.selectionStart);
	};

	// Cover image
	const addCoverImage = () => {
		hiddenCoverImageFileInputRef.current.click();
	};

	const uploadCoverImage = (event) => {
		if (event.target.files.length > 0) {
			validateCoverImage(event.target.files[0].size, event.target.files[0].type, event.target.files[0]);
		}
	};

	const validateCoverImage = async (size, type, file) => {
		const maxSize = 10 * 1024 * 1024;
		let canAddImage = true;

		if (size > maxSize) {
			canAddImage = false;
		}

		const fileTypeArray = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];

		if (!fileTypeArray.includes(type)) {
			canAddImage = false;
		}

		if (canAddImage) {
			setIsButtonDisabled(true);

			// upload image and append html to post
			const loadingToast = toast.loading('Uploading cover image...');

			const bodyFormData = new FormData();

			bodyFormData.append('image', file);

			// const devUrl = 'http://localhost:3001/api/posts/upload-cover-image';
			const productionUrl = '/api/posts/upload-cover-image';

			const response = await axios({
				method: 'post',
				url: productionUrl,
				data: bodyFormData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			if (response.data.message === 'uploaded') {
				setIsButtonDisabled(false);
				toast.dismiss(loadingToast);
				toast.success('Cover image added successfully!');

				// set cover image
				setFormData({ ...formData, coverImage: file.name });
			} else {
				setIsButtonDisabled(false);
				toast.dismiss(loadingToast);
				toast.error('Cover image upload failed!');
			}
		} else {
			toast.error('Invalid cover image!');
		}
	};

	const saveDraft = async () => {
		setIsButtonDisabled(true);

		// let's validate inputs first
		let validForm = true;
		let validDescription = true;
		let validTitle = true;
		let validAuthor = true;
		let validPost = true;

		if (formData.title === '') {
			validForm = false;
			validTitle = false;
		}

		if (formData.description === '') {
			validForm = false;
			validDescription = false;
		}

		if (formData.author === '') {
			validForm = false;
			validAuthor = false;
		}

		if (formData.body === '') {
			validForm = false;
			validPost = false;
		}

		if (!validForm) {
			setIsButtonDisabled(false);
			setFormData({ ...formData, isTitleValid: validTitle, isDescriptionValid: validDescription, isAuthorValid: validAuthor, isBodyValid: validPost });
		} else {
			let response;

			const loadingToast = toast.loading('Saving draft...');

			if (draftId) {
				// update draft
				const draftPost = {
					title: formData.title,
					description: formData.description,
					author: formData.author,
					post: formData.body,
					coverImage: formData.coverImage,
					id: draftId,
				};

				// const devUrl = 'http://localhost:3001/api/posts/update-draft';
				const productionUrl = '/api/posts/update-draft';

				response = await axios.post(productionUrl, {
					draft: draftPost,
				});
			} else {
				// save draft of post
				const draftPost = {
					title: formData.title,
					description: formData.description,
					author: formData.author,
					post: formData.body,
					coverImage: formData.coverImage,
				};

				// const devUrl = 'http://localhost:3001/api/posts/save-draft';
				const productionUrl = '/api/posts/save-draft';

				response = await axios.post(productionUrl, {
					draft: draftPost,
				});
			}

			if (response.data.result.message === 'success') {
				setIsButtonDisabled(false);
				setDraftId(response.data.result.insertId);
				toast.dismiss(loadingToast);
				toast.success('Draft saved!');
				// get date and time
				setSavedAt(getDateAndTime());
			} else {
				setIsButtonDisabled(false);
				toast.dismiss(loadingToast);
				toast.error('Unable to save draft.');
			}
		}
	};

	const previewPost = async () => {
		setIsButtonDisabled(true);

		// validate post data
		let validForm = true;
		let validTitle = true;
		let validDescription = true;
		let validAuthor = true;
		let validPost = true;
		let validCover = true;

		if (formData.title === '') {
			validForm = false;
			validTitle = false;
		}

		if (formData.description === '') {
			validForm = false;
			validDescription = false;
		}

		if (formData.author === '') {
			validForm = false;
			validAuthor = false;
		}

		if (formData.body === '') {
			validForm = false;
			validPost = false;
		}

		if (formData.coverImage === '') {
			validForm = false;
			validCover = false;
		}

		if (!validForm) {
			setIsButtonDisabled(false);
			setFormData({
				...formData,
				isTitleValid: validTitle,
				isDescriptionValid: validDescription,
				isAuthorValid: validAuthor,
				isBodyValid: validPost,
				isCoverImageValid: validCover,
			});

			if (!validCover) {
				toast.error('Please upload a valid cover image.');
			}
		} else {
			// save draft before showing preview
			let response;

			const loadingToast = toast.loading('Saving draft...');

			if (draftId) {
				// update draft
				const draftPost = {
					title: formData.title,
					description: formData.description,
					author: formData.author,
					post: formData.body,
					coverImage: formData.coverImage,
					id: draftId,
				};

				// const devUrl = 'http://localhost:3001/api/posts/update-draft';
				const productionUrl = '/api/posts/update-draft';

				response = await axios.post(productionUrl, {
					draft: draftPost,
				});
			} else {
				// save draft of post
				const draftPost = {
					title: formData.title,
					description: formData.description,
					author: formData.author,
					post: formData.body,
					coverImage: formData.coverImage,
				};

				// const devUrl = 'http://localhost:3001/api/posts/save-draft';
				const productionUrl = '/api/posts/save-draft';

				response = await axios.post(productionUrl, {
					draft: draftPost,
				});
			}

			if (response.data.result.message === 'success') {
				setIsButtonDisabled(false);
				setDraftId(response.data.result.insertId);
				toast.dismiss(loadingToast);
				toast.success('Draft saved! Generating preview...');
				setTimeout(() => {
					// navigate to preview
					navigate('/preview-post', {
						state: {
							post: {
								title: formData.title,
								description: formData.description,
								author: formData.author,
								body: formData.body,
								cover_image: formData.coverImage,
								id: draftId,
							},
						},
					});
				}, 3000);
			} else {
				setIsButtonDisabled(false);
				toast.dismiss(loadingToast);
				toast.error('Unable to save draft. Cannot display preview.');
			}
		}
	};

	const showVideoOverlay = () => {
		setVideoData({
			link: '',
			title: '',
			isLinkValid: true,
			isTitleValid: true,
		});
		setOverlayState('overlay-animate-in');
	};

	const closeOverlay = () => {
		setVideoData({
			link: '',
			title: '',
			isLinkValid: true,
			isTitleValid: true,
		});
		setOverlayState('overlay-animate-out');
	};

	const addVideo = () => {
		let validVideo = true;

		if (videoData.link === '') {
			validVideo = false;
		}

		if (videoData.title === '') {
			validVideo = false;
		}

		if (!validVideo) {
			toast.error('Invalid video details provided!');
		} else {
			// splice
			const first = formData.body.substring(0, cursorPosition);
			const second = formData.body.substring(cursorPosition);

			const splicedString =
				first +
				`<iframe className="new-post-video" src="${videoData.link}" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="${videoData.title}"></iframe>` +
				second;

			setFormData({ ...formData, body: splicedString });
			setCursorPosition(textareaRef.current.selectionStart);
			setOverlayState('overlay-animate-out');
		}
	};

	const getDateAndTime = () => {
		let now = new Date();
		let year = now.getFullYear();
		let month = now.getMonth() + 1;
		let day = now.getDate();
		let hour = now.getHours();
		let minute = now.getMinutes();
		let second = now.getSeconds();

		if (month.toString().length === 1) {
			month = '0' + month;
		}

		if (day.toString().length === 1) {
			day = '0' + day;
		}

		if (minute.toString().length === 1) {
			minute = '0' + minute;
		}

		if (second.toString().length === 1) {
			second = '0' + second;
		}

		return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
	};

	return (
		<>
			<NavBar />
			<AddVideoOverlay overlayState={overlayState} videoData={videoData} setVideoData={setVideoData} acceptClick={addVideo} cancelClick={closeOverlay} />
			<div className='container'>
				<form className='new-post-form' encType='multipart/form-data'>
					<div className='form-control'>
						<label className='form-control-label'>Post Title</label>
						<input type='text' name='title' className='form-input' value={formData.title} onChange={handleInputChange} />
						{!formData.isTitleValid && <p className='form-input-error'>Invalid title.</p>}
					</div>
					<div className='form-control'>
						<label className='form-control-label'>Post Description</label>
						<input type='text' name='description' className='form-input' value={formData.description} onChange={handleInputChange} />
						{!formData.isDescriptionValid && <p className='form-input-error'>Invalid description.</p>}
					</div>
					<div className='form-control'>
						<label className='form-control-label'>Post Author</label>
						<input type='text' name='author' className='form-input' value={formData.author} onChange={handleInputChange} />
						{!formData.isAuthorValid && <p className='form-input-error'>Invalid author.</p>}
					</div>
					<div className='form-control'>
						<label className='form-control-label'>Post Content</label>
						<textarea
							ref={textareaRef}
							className='form-input new-post-textarea'
							rows={20}
							value={formData.body}
							onChange={handleTextareaChange}></textarea>
						{!formData.isBodyValid && <p className='form-input-error'>Invalid post.</p>}
					</div>
					<div className='new-post-actions'>
						<span className='new-post-info-message'>{savedAt !== '' ? `Saved ${savedAt}` : ''}</span>
						<div className='new-post-actions-container'>
							<input type='button' className='btn-sm btn-secondary-solid' value='Paragraph' onClick={addParagraph} />
							<input type='button' className='btn-sm btn-secondary-solid' value='Emphasis' onClick={addEmphasis} />
							<input type='button' className='btn-sm btn-secondary-solid' value='Bold' onClick={addBold} />
							<input type='button' className='btn-sm btn-secondary-solid' value='Image' onClick={addImage} disabled={isButtonDisabled} />
							<input type='button' className='btn-sm btn-secondary-solid' value='Caption' onClick={addCaption} />
							<input type='button' className='btn-sm btn-secondary-solid' value='Video' onClick={showVideoOverlay} />
							<input type='file' ref={hiddenImageFileInputRef} onChange={uploadImage} style={{ display: 'none' }} />
							<input
								type='button'
								className='btn-sm btn-secondary-solid'
								value='Cover Image'
								onClick={addCoverImage}
								disabled={isButtonDisabled}
							/>
							<input type='file' ref={hiddenCoverImageFileInputRef} onChange={uploadCoverImage} style={{ display: 'none' }} />
							<input type='button' className='btn-sm btn-primary-solid' value='Draft' onClick={saveDraft} />
							<input type='button' className='btn-sm btn-primary-solid' value='Preview' onClick={previewPost} />
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default NewPostScreen;
