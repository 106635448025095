import { useRef } from 'react';
import jwt_decode from 'jwt-decode';

const useJwt = () => {
    const jwt = useRef({});

    // get token from local storage
    const token = localStorage.getItem('token');

    if (token) {
        const decoded = jwt_decode(token);

        jwt.current = decoded;
    }

    return jwt.current;
};

export default useJwt;
