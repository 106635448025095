import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import toast from 'react-hot-toast';
import axios from 'axios';

import NavBar from '../../components/NavBar';
import HomePostCard from '../../components/HomePostCard';

const PostPreviewScreen = () => {
	const { state } = useLocation();
	const [date, setDate] = useState('');
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const navigate = useNavigate();
	// console.log(state);
	useEffect(
		() => {
			// set date
			setDate(getDateAndTime());
		},
		// eslint-disable-next-line
		[],
	);

	const getDateAndTime = () => {
		let now = new Date();
		let year = now.getFullYear();
		let month = now.getMonth() + 1;
		let day = now.getDate();
		let hour = now.getHours();
		let minute = now.getMinutes();
		let second = now.getSeconds();

		if (month.toString().length === 1) {
			month = '0' + month;
		}

		if (day.toString().length === 1) {
			day = '0' + day;
		}

		if (minute.toString().length === 1) {
			minute = '0' + minute;
		}

		if (second.toString().length === 1) {
			second = '0' + second;
		}

		return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
	};

	const editPost = () => {
		navigate('/edit-draft-post', { state: { draft: [state.post] } });
	};

	const publishPost = async () => {
		const loadingToast = toast.loading('Publishing post...');

		setIsButtonDisabled(true);

		// const devUrl = 'http://localhost:3001/api/posts/publish-post';
		const productionUrl = '/api/posts/publish-post';

		// publish the post
		const response = await axios.post(productionUrl, {
			post: state.post,
		});

		if (response.data.result.message === 'success') {
			toast.dismiss(loadingToast);
			toast.success('Your post has been published! Navigating to home screen...');
			setIsButtonDisabled(false);

			setTimeout(() => {
				navigate('/home-atlast');
			}, 3000);
		} else {
			toast.dismiss(loadingToast);
			toast.error('Could not publish this post!');
			setIsButtonDisabled(false);
		}
	};

	return (
		<>
			<NavBar />
			<div className='container'>
				<HomePostCard
					title='Our First Post!'
					text={state.post.description}
					posted={date}
					author={state.post.author}
					imgSrc={`/uploads/covers/${state.post.cover_image}`}
					alt='Cover-Image'
					disabled={true}
				/>
				<h1 className='post-preview-header'>{state.post.title}</h1>
				<h4 className='post-preview-description'>{state.post.description}</h4>
				<div className='post-preview-date-and-author-container'>
					<p className='post-preview-author'>{state.post.author}</p>
					<p className='post-preview-date'>{date}</p>
				</div>
				<>{parse(state.post.body)}</>
				<div className='post-preview-actions'>
					<input type='button' className='btn-md btn-secondary-solid' value='Edit' onClick={editPost} />
					<input type='button' className='btn-md btn-primary-solid' value='Publish' onClick={publishPost} disabled={isButtonDisabled} />
				</div>
			</div>
		</>
	);
};

export default PostPreviewScreen;
