import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

const ViewPostScreen = () => {
	const [date, setDate] = useState('');
	const { state } = useLocation();
	// console.log(state);

	useEffect(
		() => {
			const dateSplit = state.post.published.split('T');

			const secondDateSplit = dateSplit[1].split('.');

			setDate(`${dateSplit[0]} ${secondDateSplit[0]}`);
		},
		// eslint-disable-next-line
		[],
	);

	return (
		<>
			<NavBar />
			<div className='container'>
				<h1 className='post-preview-header'>{state.post.title}</h1>
				<h4 className='post-preview-description'>{state.post.description}</h4>
				<div className='post-preview-date-and-author-container'>
					<p className='post-preview-author'>{state.post.author}</p>
					<p className='post-preview-date'>{date}</p>
				</div>
				<>{parse(state.post.body)}</>
			</div>
			<Footer />
		</>
	);
};

export default ViewPostScreen;
