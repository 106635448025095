import { useState, useEffect, createContext } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import ApplicationRoutes from '../routes/ApplicationRoutes';
import { Toaster } from 'react-hot-toast';

export const AppContext = createContext();

function App() {
    const [theme, setTheme] = useState();

    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');

        if (storedTheme === '' || storedTheme === null) {
            localStorage.setItem('theme', 'light');
            setTheme('light');
        } else {
            setTheme(storedTheme);
        }
    }, []);

    return (
        <AppContext.Provider value={{ theme, setTheme }}>
            <div className='App' id={theme}>
                <Router>
                    <ApplicationRoutes />
                    <Toaster
                        position='top-center'
                        reverseOrder={true}
                        gutter={8}
                        containerClassName=''
                        containerStyle={{ zIndex: 11000 }}
                        toastOptions={{
                            // Define default options
                            className: '',
                            duration: 3500,
                            style: {
                                background: '#3eb489',
                                color: '#fafafa',
                            },
                            // Default options for specific types
                            success: {
                                duration: 3500,
                                style: {
                                    backgroundColor: '#3eb489',
                                    color: '#fafafa',
                                },
                                icon: '👌',
                            },
                            error: {
                                duration: 3500,
                                style: {
                                    backgroundColor: '#f18f01',
                                    color: '#fafafa',
                                },
                                icon: '👎',
                            },
                        }}
                    />
                </Router>
            </div>
        </AppContext.Provider>
    );
}

export default App;
