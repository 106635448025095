import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BiShow, BiHide } from 'react-icons/bi';

import { AppContext } from '../../app/App';

const SignInScreen = () => {
	const { theme } = useContext(AppContext);
	const [formData, setFormData] = useState({
		username: '',
		password: '',
		isUsernameValid: true,
		isPasswordValid: true,
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [passwordType, setPasswordType] = useState('password');
	const navigate = useNavigate();

	const handleInputChange = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const validateForm = () => {
		setIsButtonDisabled(true);

		let validForm = true;
		let validUsername = true;
		let validPassword = true;

		// validate username
		if (formData.username === '') {
			validForm = false;
			validUsername = false;
		}

		// validate password
		if (formData.password.length < 10) {
			validForm = false;
			validPassword = false;
		}

		if (!validForm) {
			setIsButtonDisabled(false);
			setFormData({ ...formData, isUsernameValid: validUsername, isPasswordValid: validPassword });
			toast.error('One or more form inputs are invalid. Please check your inputs carefully.');
		} else {
			authenticateUser();
		}
	};

	const authenticateUser = async () => {
		const postUser = {
			username: formData.username,
			password: formData.password,
		};

		const loadingToast = toast.loading('Authenticating...');

		// const devUrl = 'http://localhost:3001/api/auth/authenticate-user';
		const productionUrl = '/api/auth/authenticate-user';

		const response = await axios.post(productionUrl, {
			user: postUser,
		});

		// responses - aunthenticated, wrong password, no user
		switch (response.data.result.message) {
			case 'authenticated':
				toast.dismiss(loadingToast);
				toast.success('Authenticated! Hi, ' + response.data.result.name + '.');
				localStorage.setItem('token', response.data.result.token);
				setTimeout(() => {
					navigate('/home-atlast');
				}, 3500);
				break;

			case 'wrong password':
				toast.dismiss(loadingToast);
				toast.error('Wrong password!');
				setIsButtonDisabled(false);
				break;

			case 'no user':
				toast.dismiss(loadingToast);
				toast.error('Username not found!');
				setIsButtonDisabled(false);
				break;
			default:
				break;
		}
	};

	const togglePasswordVisibility = (event) => {
		event.preventDefault();

		if (passwordType === 'password') {
			setPasswordType('text');
		} else {
			setPasswordType('password');
		}
	};

	return (
		<div className='container sign-in-container'>
			<form className='sign-in-form'>
				{theme === 'light' ? (
					<img src='/img/atlast_logo_dark.png' alt='Logo-Light' className='sign-in-logo' />
				) : (
					<img src='/img/atlast_logo_light.png' alt='Logo-Dark' className='sign-in-logo' />
				)}
				<h1 className='form-header'>AtLast</h1>
				<div className='form-control'>
					<label className='form-control-label'>Username</label>
					<input type='text' name='username' className='form-input' onChange={handleInputChange} />
					{!formData.isUsernameValid && <p className='form-input-error'>Invalid username.</p>}
				</div>
				<div className='form-control mt-md'>
					<label className='form-control-label'>Password</label>
					<div className='form-control-password-input'>
						<input type={passwordType} name='password' className='form-input form-password-input' onChange={handleInputChange} />
						{passwordType === 'password' ? (
							<div className='btn btn-xs form-password-btn' onClick={togglePasswordVisibility}>
								<BiShow />
							</div>
						) : (
							<div className='btn btn-xs form-password-btn' onClick={togglePasswordVisibility}>
								<BiHide />
							</div>
						)}
					</div>
					{!formData.isUsernameValid && <p className='form-input-error'>Invalid password.</p>}
				</div>
				<div className='form-actions mt-md'>
					<input type='button' className='btn-md btn-primary-solid' value='Sign In' onClick={validateForm} disabled={isButtonDisabled} />
					<input
						type='button'
						className='btn-md btn-primary-solid'
						value='Back'
						onClick={() => navigate('/home-atlast')}
						disabled={isButtonDisabled}
					/>
				</div>
			</form>
		</div>
	);
};

export default SignInScreen;
