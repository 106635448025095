const ComingNext = ({ title, content }) => {
	return (
		<article className='coming-next mb-md mt-md'>
			<em className='coming-soon-text'>Coming Soon...</em>
			<h2 className='coming-next-title'>{title}</h2>
			<p className='coming-next-content'>{content}</p>
		</article>
	);
};

export default ComingNext;
