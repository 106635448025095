import React from 'react';
import { useLocation } from 'react-router-dom';

const NewPostPreviewScreen = () => {
	const { state } = useLocation();
	// console.log(state);

	return <div>NewPostPreviewScreen</div>;
};

export default NewPostPreviewScreen;
