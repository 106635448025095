import { Routes, Route, Navigate } from 'react-router-dom';

// Require auth
import RequireAuth from '../config/RequireAuth';

// Auth
import SignInScreen from '../screens/Auth/SignInScreen';

// Home
import HomeScreen from '../screens/Home/HomeScreen';

// Posts
import NewPostScreen from '../screens/Posts/NewPostScreen';
import NewPostPreviewScreen from '../screens/Posts/NewPostPreviewScreen';
import DraftPostsScreen from '../screens/Posts/DraftPostsScreen';
import EditDraftPostScreen from '../screens/Posts/EditDraftPostScreen';
import PostPreviewScreen from '../screens/Posts/PostPreviewScreen';
import ViewPostScreen from '../screens/Posts/ViewPostScreen';

// Admin
import AddComingNextScreen from '../screens/Admin/AddComingNextScreen';

const ApplicationRoutes = () => {
	return (
		<Routes>
			<Route path='/' element={<Navigate to={'/home-atlast'} />} />
			<Route path='/home-atlast' element={<HomeScreen />} />
			<Route path='/sign-in' element={<SignInScreen />} />
			<Route path='/view-post' element={<ViewPostScreen />} />
			<Route
				path='/new-post'
				element={
					<RequireAuth>
						<NewPostScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/new-post-preview'
				element={
					<RequireAuth>
						<NewPostPreviewScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/draft-posts'
				element={
					<RequireAuth>
						<DraftPostsScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/edit-draft-post'
				element={
					<RequireAuth>
						<EditDraftPostScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/preview-post'
				element={
					<RequireAuth>
						<PostPreviewScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/admin-coming-next'
				element={
					<RequireAuth>
						<AddComingNextScreen />
					</RequireAuth>
				}
			/>
		</Routes>
	);
};

export default ApplicationRoutes;
