import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

import NavBar from '../../components/NavBar';
import CustomLoader from '../../components/CustomLoader';
import DraftPostCard from '../../components/DraftPostCard';

const DraftPostsScreen = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [loadingText, setLoadingText] = useState('Fetching draft posts...');
	const [draftPosts, setDraftPosts] = useState([]);
	const [noPosts, setNoPosts] = useState(false);
	const navigate = useNavigate();

	useEffect(
		() => {
			getDraftPosts();
		},
		// eslint-disable-next-line
		[],
	);

	const getDraftPosts = async () => {
		setIsLoading(true);

		// const devUrl = 'http://localhost:3001/api/posts/get-draft-posts';
		const productionUrl = '/api/posts/get-draft-posts';

		const response = await axios.get(productionUrl);

		if (response.data.result.message === 'success') {
			if (response.data.result.draftPosts.length > 0) {
				setDraftPosts(response.data.result.draftPosts);
				setIsLoading(false);
			}
		} else if (response.data.result.message === 'no posts') {
			setIsLoading(false);
			setNoPosts(true);
		} else {
			setLoadingText('Something has gone wrong!');

			setTimeout(() => {
				navigate('/home-atlast');
			}, 5000);
		}
	};

	const editDraft = (id) => {
		// get draft to edit
		const draftToBeEdited = draftPosts.filter((draft) => {
			return draft.id === id;
		});

		navigate('/edit-draft-post', { state: { draft: draftToBeEdited } });
	};

	const deleteDraft = async (id) => {
		const loadingToast = toast.loading('Deleting draft post...');
		setIsButtonDisabled(true);

		// const devUrl = 'http://localhost:3001/api/posts/delete-draft-post';
		const productionUrl = '/api/posts/delete-draft-post';

		// delete the draft
		const response = await axios.post(productionUrl, {
			id: id,
		});

		// console.log(response);

		if (response.data.result.message === 'success') {
			toast.dismiss(loadingToast);

			const filteredDraftPosts = draftPosts.filter((draft) => {
				return draft.id !== id;
			});

			setDraftPosts(filteredDraftPosts);

			if (filteredDraftPosts.length === 0) {
				setNoPosts(true);
			}

			toast.success('Draft post deleted!');
			setIsButtonDisabled(false);
		} else {
			toast.dismiss(loadingToast);
			toast.error('Could not delete draft post!');
			setIsButtonDisabled(false);
		}
	};

	return (
		<>
			{isLoading && <CustomLoader text={loadingText} />}
			<NavBar />
			<div className='container'>
				{noPosts && (
					<div className='no-draft-posts-container'>
						<div className='no-draft-posts'>No Draft Posts!</div>
					</div>
				)}
				{!noPosts && (
					<>
						{draftPosts.map((draft, index) => {
							return <DraftPostCard draft={draft} key={index} editDraft={editDraft} deleteDraft={deleteDraft} disabled={isButtonDisabled} />;
						})}
					</>
				)}
			</div>
		</>
	);
};

export default DraftPostsScreen;
