const DraftPostCard = ({ draft, deleteDraft, editDraft, disabled }) => {
    return (
        <article className='draft-post-card mb-md'>
            <div className='draft-post-card-content'>
                <h1 className='draft-post-card-title'>{draft.title}</h1>
                <p className='draft-post-card-description'>{draft.description}</p>
            </div>
            <div className='draft-post-actions'>
                <input type='button' className='btn-sm btn-primary-solid' value='Edit' onClick={() => editDraft(draft.id)} />
                <input type='button' className='btn-sm btn-secondary-solid' value='Delete' onClick={() => deleteDraft(draft.id)} disabled={disabled} />
            </div>
        </article>
    );
};

export default DraftPostCard;
