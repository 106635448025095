import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

import NavBar from '../../components/NavBar';

const AddComingNextScreen = () => {
	const [formData, setFormData] = useState({
		title: '',
		content: '',
		isTitleValid: true,
		isContentValid: true,
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const handleInputChange = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const clearForm = () => {
		setFormData({ title: '', content: '', isTitleValid: true, isContentValid: true });
	};

	const submitComingNext = async () => {
		setIsButtonDisabled(true);

		// validate input
		let validTitle = true;
		let validContent = true;
		let validForm = true;

		if (formData.title === '') {
			validTitle = false;
			validForm = false;
		}

		if (formData.content === '') {
			validContent = false;
			validForm = false;
		}

		if (!validForm) {
			setFormData({ ...formData, isTitleValid: validTitle, isContentValid: validContent });
		} else {
			const loadingToast = toast.loading('Saving coming next post...');
			setIsButtonDisabled(true);

			const comingNextPost = {
				title: formData.title,
				content: formData.content,
			};

			// const devUrl = 'http://localhost:3001/api/admin/save-coming-next';
			const productionUrl = '/api/admin/save-coming-next';

			// save coming next
			const response = await axios.post(productionUrl, {
				postData: comingNextPost,
			});

			if (response.data.result.message === 'success') {
				setIsButtonDisabled(false);
				toast.dismiss(loadingToast);
				toast.success('Coming next post saved!');
				setFormData({ title: '', content: '', isTitleValid: true, isContentValid: true });
			} else {
				setIsButtonDisabled(false);
				toast.dismiss(loadingToast);
				toast.error('Unable to save coming next post at this time. Please try again.');
			}
		}
	};

	return (
		<>
			<NavBar />
			<div className='container'>
				<form className='coming-next-form' encType='multipart/form-data'>
					<div className='form-control'>
						<label className='form-control-label'>Coming Next Title</label>
						<input type='text' name='title' className='form-input' value={formData.title} onChange={handleInputChange} />
						{!formData.isTitleValid && <p className='form-input-error'>Invalid title.</p>}
					</div>
					<div className='form-control'>
						<label className='form-control-label'>Coming Next Content</label>
						<textarea
							className='form-input coming-next-textarea'
							rows={20}
							name='content'
							value={formData.content}
							onChange={handleInputChange}></textarea>
						{!formData.isContentValid && <p className='form-input-error'>Invalid content.</p>}
					</div>
				</form>
				<div className='coming-next-actions'>
					<div className='coming-next-actions-container'>
						<input type='button' className='btn-md btn-secondary-solid' value='Clear' onClick={clearForm} disabled={isButtonDisabled} />
						<input type='button' className='btn-md btn-primary-solid' value='Save' onClick={submitComingNext} disabled={isButtonDisabled} />
					</div>
				</div>
			</div>
		</>
	);
};

export default AddComingNextScreen;
