import { useState, useEffect } from 'react';

const HomePostCard = ({ title, text, posted, author, imgSrc, alt, onClick, id }) => {
	const [truncatedText, setTruncatedText] = useState(text);
	const [date, setDate] = useState('');

	useEffect(
		() => {
			truncateText();

			if (posted === '' || posted === null || posted === undefined) {
				setDate('0000-00-00 00:00:00');
			} else {
				const dateSplit = posted.split('T');

				const secondDateSplit = dateSplit[1].split('.');

				setDate(`${dateSplit[0]} ${secondDateSplit[0]}`);
			}
		},
		// eslint-disable-next-line
		[],
	);

	const truncateText = () => {
		if (text.length > 300) {
			setTruncatedText(text.substring(0, 300) + '...');
		} else {
			setTruncatedText(text + '...');
		}
	};

	return (
		<article className='post-card mb-md'>
			<div className='post-card-image'>
				<img src={imgSrc} alt={alt} />
			</div>
			<div className='post-card-content'>
				<h1 className='post-card-title'>{title}</h1>
				<div className='post-date-and-author-container'>
					<em className='post-card-author'>{author}</em>
					<em className='post-card-date'>{date}</em>
				</div>
				<p className='post-card-clipped-content'>{truncatedText}</p>
				<input type='button' className='btn-md btn-primary-solid' value='Read More' onClick={onClick} id={id} />
			</div>
		</article>
	);
};

export default HomePostCard;
