import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { BsYoutube } from 'react-icons/bs';

const Footer = () => {
	const [year, setYear] = useState();
	const [formData, setFormData] = useState({
		email: '',
		isEmailValid: true,
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [subscribed, setSubscribed] = useState(false);

	useEffect(() => {
		const date = new Date();

		setYear(date.getFullYear());

		// check if user has subscribed to mail list
		const ml = localStorage.getItem('ml');

		if (ml !== undefined && ml !== null) {
			// console.log('fooooo');
			setSubscribed(true);
		}
	}, []);

	const handleInputChange = (event) => {
		setFormData({ ...formData, email: event.target.value });
	};

	const validateEmail = () => {
		setIsButtonDisabled(true);

		let validForm = true;
		let validEmail = true;

		// Validate email
		if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
			validEmail = false;
			validForm = false;
		}

		setFormData({ ...formData, isEmailValid: validEmail });

		if (!validForm) {
			toast.error('Invalid email provided! Please provide a valid email.');
			setIsButtonDisabled(false);
		} else {
			saveEmailForNotifications();
		}
	};

	const saveEmailForNotifications = async () => {
		const loadingToast = toast.loading('Saving email...');

		// const devUrl = 'http://localhost:3001/api/mail/save-email';
		const productionUrl = '/api/mail/save-email';

		const response = await axios.post(productionUrl, {
			email: formData.email,
		});

		if (response.data.result.message === 'success') {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			// store uuid in local storage
			localStorage.setItem('ml', response.data.result.uuid);
			setSubscribed(true);
			toast.success('Thanks for signing up!');
		} else {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.error('Unable to sign up for mail list at this time. Please try again.');
		}
	};

	const unsubscribeFromMailList = async () => {
		const loadingToast = toast.loading('Unsubscribing...');

		const mluuid = localStorage.getItem('ml');

		// const devUrl = 'http://localhost:3001/api/mail/unsubscribe-email';
		const productionUrl = '/api/mail/unsubscribe-email';

		const response = await axios.post(productionUrl, {
			uuid: mluuid,
		});

		if (response.data.result.message === 'success') {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			// remove ml from local storage
			localStorage.removeItem('ml');
			setSubscribed(false);
			toast.success('Successfully unsubscribed!');
		} else {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.error('Unable to unsubscribe at this time. Please try again.');
		}
	};

	const socialMediaClick = (type) => {
		switch (type) {
			case 'yt':
				window.open('https://www.youtube.com/channel/UCcPwBDomHM35jtHbCYKpTTw', '_blank');
				break;

			default:
				break;
		}
	};

	return (
		<footer className='footer'>
			<div className='footer-container'>
				<div className='footer-icon-container'>
					<img src='/img/footer_gif.gif' className='footer-gif' alt='Footer-Icon' />
				</div>
				{subscribed ? (
					<div className='footer-subscribed-container'>
						<h5 className='footer-subscribed-header'>Thanks for signing up for post notifications! Feel free to turn this off at any time.</h5>
						<input
							type='button'
							className='btn-md footer-btn btn-primary-solid'
							value='Unsubscribe'
							onClick={unsubscribeFromMailList}
							disabled={isButtonDisabled}
						/>
					</div>
				) : (
					<div className='footer-email-sign-up'>
						<input type='email' className='footer-input' placeholder='Get email notifications for new posts...' onChange={handleInputChange} />
						<input
							type='button'
							className='btn btn-xs footer-btn btn-primary-solid'
							value='Notify Me!'
							onClick={validateEmail}
							disabled={isButtonDisabled}
						/>
					</div>
				)}
				<div className='footer-social-media-links'>
					<span
						onClick={() => {
							socialMediaClick('yt');
						}}>
						<BsYoutube />
					</span>
				</div>
				<h4 className='footer-copyright'>&copy; Matthew Reeves Davis {year}</h4>
			</div>
		</footer>
	);
};

export default Footer;
