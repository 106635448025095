import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import NavBar from '../../components/NavBar';
import ComingNext from '../../components/ComingNext';
import CustomLoader from '../../components/CustomLoader';
import HomePostCard from '../../components/HomePostCard';
import Footer from '../../components/Footer';

const HomeScreen = () => {
	const [comingNext, setComingNext] = useState([]);
	const [isComingNext, setIsComingNext] = useState(false);
	const [posts, setPosts] = useState([]);
	const [havePosts, setHavePosts] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		getHomeScreenData();
	}, []);

	const getHomeScreenData = async () => {
		setIsLoading(true);

		// const devUrl = 'http://localhost:3001/api/posts/get-posts';
		const productionUrl = '/api/posts/get-posts';

		const response = await axios.get(productionUrl);

		if (response.data.result.message === 'success') {
			setIsLoading(false);
			setPosts(response.data.result.posts);
			setComingNext(response.data.result.comingNext);

			if (response.data.result.comingNext.message !== 'no post') {
				setIsComingNext(true);
			}

			if (response.data.result.posts.length > 0) {
				setHavePosts(true);
			}
		}
	};

	const readMore = (event) => {
		const postToBeViewed = posts.filter((post) => {
			return post.id.toString() === event.target.id;
		});

		navigate('/view-post', {
			state: {
				post: postToBeViewed[0],
			},
		});
	};

	return (
		<>
			{/* <div className='container'>
				<div className='m-container'>
					<img className='m-image' src='/img/atlast_logo_dark.png' alt='Down-Img' />
					<p className='m-message'>Down for maintenance at the moment, be back soon!</p>
				</div>
			</div> */}
			{isLoading && <CustomLoader text='Fetching posts...' />}
			<NavBar />
			<div className='container'>
				<article className='life-motto'>
					To see the world, things dangerous to come to, to see behind walls, draw closer, to find each other, and to feel. That is the purpose of
					life.
				</article>
				{isComingNext && <ComingNext title={comingNext.title} content={comingNext.content} />}
				{havePosts && (
					<>
						{posts.map((post, index) => {
							return (
								<HomePostCard
									title={post.title}
									text={post.description}
									posted={post.published}
									author={post.author}
									imgSrc={`/uploads/covers/${post.cover_image}`}
									alt='Post Cover Image'
									key={index}
									onClick={readMore}
									id={post.id}
								/>
							);
						})}
					</>
				)}
				{!havePosts && (
					<div className='no-posts-container'>
						<div className='no-posts'>No Posts Yet!</div>
					</div>
				)}
			</div>
			{!isLoading && <Footer />}
		</>
	);
};

export default HomeScreen;
